<template>
  <b-row class="match-height">
    <b-col lg="12">
      <FavoriteList />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FavoriteList from '@/components/company/favorite/FavoriteList.vue'

export default {
  components: {
    BRow,
    BCol,
    FavoriteList,
  },
}
</script>

<style lang="scss">
</style>
